import React from "react";
import { Link } from 'react-router-dom';

const Projects = () => {
    return (

        <div className="content">
            <section id="hero">
                <img src="images/tableau_ws.jpg" />
                <h1>
                Jason Livingston
                </h1>
                <h3>
                Data Science and Programming
                </h3>
            </section>
            
            <section>
                <h2>Articles</h2>
                <ul>
                    <li><Link to="/articles/sinusoids">Sinusoidal Features for Periodic Models</Link></li>
                    <li><Link to="/articles/ai-negation">The Negation Problem in Artificial Intelligence</Link></li>
                    <li><Link to="/articles/multi-target-regression">Multi-Target Regression Coefficients Resolve to Single-Target Regression Expected Values</Link></li>
                    <li><Link to="/articles/glm-distributions">Decoupling Generalized Linear Model Distribution Choices from Canonical Link Functions</Link></li>
                    <li><Link to="/articles/regression-inversion">Inverting Regressions y~f(x) Yielding Sub-optimial Estimators for x~g(y)</Link></li>
                </ul>
            </section>
        
            <section>
                <p>
                    My personal projects are highlights from the Machine Learning 
                    and Python work that I like do in my free time.
                </p>
                <p>
                    Much of the code I like to share publicly can be found in my <a href="https://github.com/JLivingston01" class="hyperlink">
                    public GitHub
                    </a>.
                </p>
            </section>
        </div>

    );
};

export default Projects;